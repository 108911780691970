<template>
  <div class="body_wrap">


    <div class="page_wrap">


      <div class="page_content_wrap">
        <div class="content_wrap">

          <div class="content">
            <div data-elementor-type="wp-page" data-elementor-id="5301" class="elementor elementor-5301">
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <router-view :key="$route.fullPath"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import firebase from "firebase";
import {version} from "../../package.json";
import globalFunctions from "@/helpers/globalFunctions";
import update from '../mixins/update'
import _ from "lodash";

export default {
  name: "EduriTemplate",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Zapisi spomina",
    // all titles will be injected into this template
    titleTemplate: "%s | Zapisi spomina",
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: "EduriWidget",
        screen_name: metaInfo.title,
        app_version: version
      });
    }
  },
  components: {},
  computed: {
    isPWA() {
      return globalFunctions.isPWA()
    }
  },
  data() {
    return {
      prompt: false
    };
  },
  mixins: [update],
  beforeCreate() {

  },
  beforeMount() {
    console.log("beforeMount");
    this.removeStyles();

  },
  mounted() {
    window.onload = function() {
      Array.prototype.forEach.call(window.parent.document.querySelectorAll("link[rel=stylesheet]"), function(link) {
        var newLink = document.createElement("link");
        newLink.rel  = link.rel;
        newLink.href = link.href;
        document.head.appendChild(newLink);
      });
    };

    const _this = this;
    console.log("mounted");

    document.body.classList.add('eDuri',  'page-template', 'page-template-elementor_header_footer', 'page', 'page-id-5301', 'wp-custom-logo', 'hide_fixed_rows_enabled', 'tribe-js', 'skin_default', 'scheme_default', 'blog_mode_page', 'body_style_wide', 'is_single', 'sidebar_hide', 'expand_content', 'trx_addons_present', 'header_type_custom', 'header_style_header-custom-1780', 'header_position_default', 'menu_side_none', 'fixed_blocks_sticky', 'elementor-default', 'elementor-template-full-width', 'elementor-kit-1033', 'elementor-page', 'elementor-page-5301', 'e--ua-blink')
    document.body.style.margin = "0px";

    let externalStyles = [
      'https://www.e-duri.si/wp-content/themes/learnify/skins/default/css/__custom.css',
      'https://www.e-duri.si/wp-content/plugins/learndash-learndash-course-grid-ba4894b1408a/templates/skins/legacy-v1/style.css',
      'https://www.e-duri.si/wp-content/plugins/elementor/assets/css/frontend.min.css',
      'https://www.e-duri.si/wp-content/themes/learnify/skins/default/css/__plugins.css',
      'https://www.e-duri.si/wp-content/themes/learnify/skins/default/css/font-face/Montserrat/stylesheet.css',
      'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;400;500;700;900&display=swap'
    ];
    setTimeout(() => {
      _.each(externalStyles, function (val) {
        _this.addExternalStyle(val);
      })
    }, 0);

  },

  methods: {
    removeStyles() {
      document.querySelectorAll('[style]').forEach(el => el.removeAttribute('style'));
      document.querySelectorAll('link[rel="stylesheet"]').forEach(el => el.parentNode.removeChild(el));
      document.querySelectorAll('style').forEach(el => el.parentNode.removeChild(el));
    },
    addExternalStyle(styleUrl) {
      let element = document.createElement("link");
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("property", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("href", styleUrl + '?v=' + this.moment().valueOf());
      document.getElementsByTagName("head")[0].appendChild(element);
    }
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;400;500;700;900&display=swap');
</style>
